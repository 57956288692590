<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div class="flex justify-end">
      <asom-button icon="trash" text="Discard All" variant="error" @click="showDiscardAllModal = true" />
    </div>
    <asom-client-table
      v-if="!isLoading"
      :columns="columns"
      :data="list"
      :filterable="true"
      :pagination="true"
    >
      <template v-slot:supplyDate="scopedSlots">
        <span>{{ displayUtcDate(scopedSlots.data) }}</span>
      </template>
      <template v-slot:expiryDate="scopedSlots">
        <span>{{ displayUtcDate(scopedSlots.data) }}</span>
      </template>
      <template v-slot:itemDescription="scopedSlots">
        <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
      </template>
      <template v-slot:action="scopedSlots">
        <asom-button icon="trash" text="Discard" variant="error" @click="toggleModal(scopedSlots.rowData.inventoryId)" />
      </template>
    </asom-client-table>
    <div v-else class="text-center">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
  </div>
  <asom-modal
    v-model="showDiscardAllModal"
    title="Discard All Expired Items"
  >
      <p class="pt-4">Do you want to discard all current expired items?</p>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="confirmDiscardAll"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          variant="error"
          text="Confirm"
        />
        <asom-button
          @click="showDiscardAllModal = false"
          text="Cancel"
          variant="secondary"
        />
      </div>
  </asom-modal>
  <asom-modal
    v-model="showDiscardModal"
    title="Discard Expired Item"
  >
      <p class="pt-4">Do you want to discard the expired item <strong>{{selectedInventoryName}}?</strong></p>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="confirmDiscard"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          variant="error"
          text="Confirm"
        />
        <asom-button
          @click="showDiscardModal = false"
          text="Cancel"
          variant="secondary"
        />
      </div>
  </asom-modal>
</template>
<script>
import get from "lodash.get";
import { mapGetters } from 'vuex';
import { LIST_EXPIRED_INVENTORIES, DISCARD_EXPIRED_INVENTORIES } from '../../constants/apis';
import { getAsync, postAsync } from '../../services/serviceHandlers';
import { displayUtcDate } from "@/helpers/dateTimeHelpers";

export default {
  data() {
    return {
      list: [],
      isLoading: false,
      error: null,
      showDiscardAllModal: false,
      showDiscardModal: false,
      selectedId: null,
      isSubmitting: false,
    }
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "auth/userLineId",
    }),
    columns() {
      return [
        "inventoryTypeName",
        "amount",
        "primaryLocationName",
        "secondaryLocationName",
        "supplyDate",
        "expiryDate",
        "itemDescription",
        "action",
      ];
    },
    selectedInventoryName() {
      const inventory = this.list.find(({ inventoryId }) => inventoryId == this.selectedId);
      return get(inventory, 'inventoryTypeName', '');
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    toggleModal(inventoryId) {
      this.selectedId = inventoryId;
      this.showDiscardModal = true;
    },
    displayUtcDate,
    async loadData() {
      this.error = null;
      const result = await getAsync({
        url: LIST_EXPIRED_INVENTORIES,
        params: {
          stationId: this.stationId,
          lineId: this.lineId,
        }
      });
      if (result.success) {
        this.list = get(result, 'payload.list', []);
      } else {
        this.error = result.payload;
      }
      this.isLoading = false;
    },
    async confirmDiscard() {
      this.isSubmitting = true;
      const resp = await postAsync({
        url: DISCARD_EXPIRED_INVENTORIES,
        body: {
          stationId: this.stationId,
          lineId: this.lineId,
          inventoryIds: [ this.selectedId ],
        }
      });
      if (resp.success) {
        this.loadData();
        this.showDiscardModal = false;
        this.selectedId = null;
      } else {
        this.error = resp.payload;
      }
      this.isSubmitting = false;
    },
    async confirmDiscardAll() {
      this.isSubmitting = true;
      const inventoryIds = this.list.map(({ inventoryId }) => inventoryId);
      const resp = await postAsync({
        url: DISCARD_EXPIRED_INVENTORIES,
        body: {
          stationId: this.stationId,
          lineId: this.lineId,
          inventoryIds,
        }
      });
      if (resp.success) {
        this.showDiscardModal = false;
        this.$router.push({ name: 'Inventory Main Page'});
      } else {
        this.error = resp.payload;
      }
      this.isSubmitting = false;
    }
  }
}
</script>